@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO: only possible to use @apply in tailwind v4, currently in alpha */
/* @layer components {
  .badge {
    @apply badge;
    &-success-high-contrast {
      @apply border-neutral bg-neutral text-success-high-contrast-content;
    }
    &-warning-high-contrast {
      @apply border-neutral bg-neutral text-warning-high-contrast-content;
    }
  }
} */
